import * as yup from 'yup'
import { ContainerDtoContainerTypeEnum, ShipownerDto, TransitionDto } from '../api'
import { TransitionMode } from '../enums/TransitionMode'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { ConfigContext } from '../context/ConfigContext'

export const TransitionFormValidationScheme = (transitionMode: TransitionMode, mscShipownerId?: number) => {
  const { t } = useTranslation()
  const { generalCarrierReference, heavyTested, damageLogRequiredByKiosk } = useContext(ConfigContext)

  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)

  let semitrailerLicencePlateValidation = yup.string().nullable(true)

  let qualityType
  let shippingContainerType
  let semitrailerType
  let grossWeightValidation
  let tarraWeightValidation
  let shipownerValidation
  let bookingReferenceValidation
  let medlogReferenceValidation
  let importExportValidation
  let heavyTestedValidation = yup.number().nullable(true).notRequired()

  const netWeightValidation = yup
    .number()
    .when(['repleted'], {
      is: true,
      then: yup
        .number()
        .transform((currentValue, originalValue) => {
          return !originalValue ? undefined : currentValue
        })
        .min(0, t('pages.gateIn.form.netWeight.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero'))
        .typeError(t('pages.gateIn.validations.netWeight.notNumber')),
    })
    .typeError(t('pages.gateIn.validations.netWeight.notNumber'))

  const damageLogEntriesValidation = damageLogRequiredByKiosk
    ? yup
        .array()
        .when(['damaged'], {
          is: true,
          then: yup.array().required(t('pages.gateIn.validations.damageLogEntries')),
        })
        .notRequired()
    : yup.array().notRequired()

  if (transition?.container?.containerType === ContainerDtoContainerTypeEnum.Semitrailer) {
    semitrailerLicencePlateValidation = yup
      .string()
      .required(t('pages.gateIn.validations.semitrailerLicencePlate.required'))
      .min(5, t('pages.gateIn.validations.semitrailerLicencePlate.min', { min: 5 }))
      .max(10, t('pages.gateIn.validations.semitrailerLicencePlate.max', { max: 10 }))
    semitrailerType = yup.string().not(['NOT-SELECTED'], t('pages.gateIn.validations.semitrailerType.notSelected'))
    shippingContainerType = yup.string().nullable(true)
    qualityType = yup.string().nullable(true)
    shipownerValidation = yup.object()
    bookingReferenceValidation = yup.string().nullable(true)
    medlogReferenceValidation = yup.string().nullable(true)
    importExportValidation = yup.string()
  } else if (transition?.container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer) {
    qualityType = yup
      .string()
      .nullable(true)
      .not(['NOT-SELECTED'], t('pages.gateIn.validations.qualityType.notSelected'))
    shippingContainerType = yup
      .string()
      .not(['NOT-SELECTED'], t('pages.gateIn.validations.shippingContainerType.notSelected'))
    semitrailerType = yup.string().nullable(true)
    shipownerValidation = yup.object({
      id: yup
        .mixed()
        .required(t('pages.gateIn.validations.shipowner.notSelected'))
        .not(['NOT-SELECTED'], t('pages.gateIn.validations.shipowner.notSelected')),
    })
    importExportValidation = yup.string()

    if (transition.container.heavyTested || (heavyTested && !transition.repleted)) {
      heavyTestedValidation = yup
        .number()
        .required(t('pages.gateIn.validations.heavyTestedValue.notSelected'))
        .min(
          0,
          t('pages.gateIn.form.heavyTestedValue.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero')
        )
        .typeError(t('pages.gateIn.validations.heavyTestedValue.notNumber'))
    } else {
      heavyTestedValidation = yup
        .number()
        .nullable(true)
        .notRequired()
        .min(
          0,
          t('pages.gateIn.form.heavyTestedValue.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero')
        )
        .typeError(t('pages.gateIn.validations.heavyTestedValue.notNumber'))
    }

    // MSC + repleted - booking reference, if MSC + repleted=false - medlog reference
    bookingReferenceValidation = yup
      .string()
      .nullable(true)
      .when(['container.shipowner', 'repleted'], {
        is: (shipowner: ShipownerDto, repleted?: boolean) => shipowner.id === mscShipownerId && !!repleted,
        then: yup
          .string()
          .required(t('pages.gateIn.validations.bookingReference'))
          .nullable(false)
          .typeError(t('pages.gateIn.validations.bookingReference')),
      })
    // as a workaround we need to use data from transition instead of controlled form, controlled form does not have any updated value
    if (transition.container?.shipowner?.id === mscShipownerId && !transition.repleted) {
      medlogReferenceValidation = yup
        .string()
        .required(
          generalCarrierReference
            ? t('pages.gateIn.validations.carrierReference')
            : t('pages.gateIn.validations.medlogReference')
        )
        .nullable(false)
        .typeError(
          generalCarrierReference
            ? t('pages.gateIn.validations.carrierReference')
            : t('pages.gateIn.validations.medlogReference')
        )
    } else {
      medlogReferenceValidation = yup.string().notRequired().nullable(true)
    }
  } else {
    shippingContainerType = yup.string().notRequired()
    semitrailerType = yup.string().notRequired()
    medlogReferenceValidation = yup.string().notRequired()
    qualityType = yup.string().notRequired()
    shipownerValidation = yup.object()
    bookingReferenceValidation = yup.string().notRequired()
    importExportValidation = yup.string().notRequired()
    heavyTestedValidation = yup.number().nullable(true).notRequired()
  }

  if (transitionMode === TransitionMode.SEARCH || transitionMode === TransitionMode.GATE_OUT) {
    grossWeightValidation = yup
      .number()
      .nullable(true)
      .min(0, t('pages.gateIn.form.grossWeight.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero'))
      .typeError(t('pages.gateIn.validations.grossWeight'))
    tarraWeightValidation = yup
      .number()
      .required(t('pages.gateIn.validations.tarraWeight.notSelected'))
      .transform((currentValue, originalValue) => {
        return originalValue === '' || originalValue === null ? undefined : currentValue
      })
      .nullable(false)
      .min(0, t('pages.gateIn.form.tarraWeight.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero'))
      .typeError(t('pages.gateIn.validations.tarraWeight.notNumber'))
  } else {
    grossWeightValidation = yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' || originalValue === null ? undefined : currentValue
      })
      .min(0, t('pages.gateIn.form.grossWeight.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero'))
      .typeError(t('pages.gateIn.validations.grossWeight'))
    tarraWeightValidation = yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' || originalValue === null ? undefined : currentValue
      })
      .required(t('pages.gateIn.validations.tarraWeight.notSelected'))
      .nullable(false)
      .min(0, t('pages.gateIn.form.tarraWeight.label') + ' ' + t('error.constraintValidation.code.PositiveOrZero'))
      .typeError(t('pages.gateIn.validations.tarraWeight.notNumber'))
  }

  return yup.object().shape({
    container: yup.object().shape({
      iluCode: yup.string().trim().required(t('pages.gateIn.validations.iluCode.required')),
      semitrailerLicencePlate: semitrailerLicencePlateValidation,
      tarraWeight: tarraWeightValidation,
      shipowner: shipownerValidation,
      company: yup.object().shape({
        id: yup.string().not(['NOT-SELECTED'], t('pages.gateIn.validations.companyId.notSelected')),
        parentCompanyId: yup.string().not(['NOT-SELECTED'], t('pages.gateIn.validations.parentCompanyId.notSelected')),
      }),
      shippingContainerType: shippingContainerType,
      semitrailerType: semitrailerType,
      heavyTestedValue: heavyTestedValidation,
    }),
    transitionAdditionalInfo: yup.object().shape({
      medlogReference: medlogReferenceValidation,
    }),
    qualityType: qualityType,
    grossWeight: grossWeightValidation,
    netWeight: netWeightValidation,
    bookingReference: bookingReferenceValidation,
    importExport: importExportValidation,
    temporaryCustomer: yup.object().shape({
      id: yup.string().not(['NOT-SELECTED'], t('pages.gateIn.validations.companyId.notSelected')),
    }),
    damageLogEntries: damageLogEntriesValidation,
  })
}
